import * as React from "react"
import { useState, useLayoutEffect, useContext, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { BsArrowRight } from "react-icons/bs"
import { MdPlayArrow } from "react-icons/md"
import "../components/css/company.scss"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { StaticImage } from "gatsby-plugin-image"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { Link } from "gatsby"
import GlobalContext from "../context/GlobalContext"
import Image01 from "../images/company/mo-islam.png"
import Image02 from "../images/company/jerry-yang.png"
import Image03 from "../images/company/homan-yuen.jpg"
import Image04 from "../images/company/Alex-Weiss.png"
import Image05 from "../images/company/Jeff-Cunningham.png"
import Image06 from "../images/company/Paul-Jacobss.png"
import Image07 from "../images/company/Matt-Grob.png"
import Image08 from "../images/company/Christos-Kozyrakiss.jpg"
import Image09 from "../images/company/Rene-Haas.jpg"
import Image10 from "../images/company/Ajit-Pai.jpg"
import Image11 from "../images/company/jim_anderson.jpg"
import mobimage from "../images/company/Mobile_Breakthrough_Award-Badge_2022-EdgeQ.png"
import glomoimagejpg from "../images/company/GLOMO-Awards.jpg"
import { BsArrowUp } from "react-icons/bs"
import { FaLinkedin } from "react-icons/fa"
import { Helmet } from "react-helmet"

const Company = () => {
  const [sliderRef] = useKeenSlider({
    breakpoints: {
      "(max-width: 1199px)": {
        slides: { perView: 3, spacing: -30 },
      },
      "(max-width: 991px)": {
        slides: { perView: 2, spacing: 5 },
      },
    },
    loop: false,
    slides: {
      perView: 4,
      spacing: -30,
    },
  })

  const globalContext = useContext(GlobalContext)
  const {
    isAvailable,
    setIsAvailable,
    isDrag,
    setIsDrag,
    closeClass,
    setcloseClass,
  } = globalContext

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //Counter
  useLayoutEffect(() => {
    const counters = document.querySelectorAll(".counter")

    function runCounter() {
      counters.forEach(counter => {
        counter.innerText = 0
        let target = +counter.dataset.count
        let step = target / 100

        let countIt = function () {
          let displayedCount = +counter.innerText

          if (displayedCount < target) {
            counter.innerText = Math.ceil(displayedCount + step)
            setTimeout(countIt, 20)
          } else {
            counter.innerText = target
          }
        }
        countIt()
      })
    }

    let counterSection = document.querySelector(".counter1")

    let options = {
      rootMargin: "0px 0px -200px 0px",
    }

    let done = 0

    const sectionObserver = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting && done !== 2) {
        done++

        runCounter()
      }
    }, options)

    sectionObserver.observe(counterSection)
  }, [])

  const [tabIndex, setTabIndex] = React.useState({ tabIndex: 0 })

  // Investor Slider
  const [sliderArr, setSliderArr] = useState([
    {
      path:Image11,
      name:"Jim Anderson",
      designation: "Lattice Semiconductor | CEO & President",
      aLink: "https://ir.latticesemi.com/management/jim-anderson",
    },
    {
      path: Image01,
      name: "mo islam",
      designation: "threshold vc | partner",
      aLink: "https://threshold.vc/team/mohammad-mo-islam/",
    },
    // {
    //   path: Image02,
    //   name: "jerry yang",
    //   designation: "AME Cloud Ventures | partner",
    //   aLink: "https://www.amecloudventures.com/#team",
    // },
    // {
    //   path: Image03,
    //   name: "homan yuen",
    //   designation: "Fusion Funds | partner",
    //   aLink: "https://www.fusionfund.com/team/homanyuen",
    // },
    {
      path: Image04,
      name: "Alex Weiss",
      designation: "Clearsky | Partner",
      aLink:
        "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQH2rmZTZP1DYAAAAX8IAwUQg0t3RTByr-MbopuJHC6TPxix9fTa6frFWudEtm29mBUklPSi0Tl1Qa45xOuqulj2coE7EAF5lJHHQb7kH_EeqrkGd6hTHjVP6ngaMfXf3opqrIk=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Falex-weiss-b1124249%2F",
    },
    {
      path: Image05,
      name: "Jeff Cunningham",
      designation: "Lockheed Martin | Investment Manager",
      aLink:
        "https://www.lockheedmartin.com/en-us/who-we-are/lockheed-martin-ventures/jeff-cunningham.html",
    },
  ])

  // Advisor Slider
  const [advisorSlider, setAdvisorSlider] = useState([

    {
      path: Image06,
      name: "Paul Jacobs",
      designation: "XCOM Labs | CEO",
      aLink: "https://xcom-labs.com/meet-the-team/#PAUL",
    },
    {
      path: Image07,
      name: "Matt Grob",
      designation: "XCOM Labs | CTO",
      aLink: "https://xcom-labs.com/meet-the-team/#MATT",
    },
    {
      path: Image10,
      name: "Ajit Pai",
      designation: "FCC | Former Chairman",
      aLink: "https://www.searchlightcap.com/team/ajit-pai/",
    },
    {
      path: Image08,
      name: "Christos Kozyrakis",
      designation: "Professor | Stanford University",
      aLink: "https://profiles.stanford.edu/christoforos-kozyrakis",
    }    
  ])

  const showDetails = idx => {
    setcloseClass(!closeClass)

    setSliderArr(
      sliderArr.map(sl => {
        if (sl.id === idx) {
          return { ...sl, isChecked: !sl.isChecked }
        }
        return sl
      })
    )
    if (sliderArr[idx].isChecked) {
      setcloseClass(!closeClass)
    }
  }

  const showDetailsAdvisor = idx => {
    setcloseClass(!closeClass)

    setAdvisorSlider(
      advisorSlider.map(sl => {
        if (sl.id === idx) {
          return { ...sl, isChecked: !sl.isChecked }
        }
        return sl
      })
    )
    if (advisorSlider[idx].isChecked) {
      setcloseClass(!closeClass)
    }
  }
  const mouseEnter = id => {
    if (sliderArr[id].isChecked) {
      setcloseClass(!closeClass)
      // setcloseClass(!closeClass)
    }
  }

  const mouseEnterAdv = id => {
    if (advisorSlider[id].isChecked) {
      setcloseClass(!closeClass)
      // setcloseClass(!closeClass)
    }
  }
  const mouseLeave = () => {
    setcloseClass(false)
  }

  const mouseLeaveAdv = () => {
    setcloseClass(false)
  }

  useEffect(() => {
    sliderArr.forEach((slides, index) => {
      slides.isChecked = false
      slides.id = index
    })

    advisorSlider.forEach((slides, index) => {
      slides.isChecked = false
      slides.id = index
    })
  }, [])

  return (
    <Layout>
      <Seo title="EdgeQ | Company" />
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Company - EdgeQ" />
        <meta
          property="og:description"
          content="our passion About EdgeQ EdgeQ is a leading innovator creating the industry&#8217;s first 5G Base-Station-on-a-Chip. Led by seasoned executives from Qualcomm, Intel, and Broadcom who have delivered industry-transforming technologies (4G/5G, WiFi, Wimax, Artificial Intelligence, Cloud Servers) for the last few decades, EdgeQ is inventing a new paradigm within the wireless infrastructure industry. Our vision is &hellip; Company Read More &raquo;"
        />
        <meta property="og:url" content="https://edgeq.io/company/" />
        <meta property="og:site_name" content="EdgeQ" />
        <meta
          property="article:modified_time"
          content="2021-03-08T21:44:16+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="4 minutes" />
      </Helmet>

      <section className="company-first-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="company-heading">
                <h2 data-aos="fade-down">
                  Our vision, <br />{" "}
                  <span className="orange uppercase">5G+AI </span> in a{" "}
                  <span className="orange">hyper-connected </span> world
                </h2>
              </div>
            </Col>
          </Row>
          <section className="meet-our-sec">
            <Row>
              <Col md={12}>
                <div className="meetour-head">
                  <h3>
                    <span className="right-arrow">
                      <BsArrowRight />
                    </span>
                    About EdgeQ
                  </h3>
                </div>
              </Col>
              
              <Col md={12}>
                <div className="about-sec-logo">
                <div className="about-sec text">
                <p>
                  Our vision is to democratize 5G in an open paradigm, where
                  customers can access, customize, and deploy 5G purely through
                  software. Our mission is to accelerate cloud migration to the
                  closest point of the edge where all data processing and
                  connectivity occurs.
                </p>
              </div>
                  <Row>
                    <Col md={3}>
                      <div className="about-sec-logo-img">
                        <img src={glomoimagejpg} alt="" />
                      </div>
                    </Col>
                    <Col md={9}>
                      <div className="about-sec">
                        <p>2023 GLOMO Award Winner</p>
                        <p>
                          CTO Choice Award for Outstanding Mobile Technology
                        </p>
                        <p>
                          Best Digital Technology Breakthrough for Companies
                          with Under $10M Annual Global Revenue
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12}>
                <div className="about-sec-logo">
                  <Row>
                    <Col md={3}>
                      <div className="about-sec-logo-img">
                        <img src={mobimage} alt="" />
                      </div>
                    </Col>
                    <Col md={9}>
                      <div className="about-sec">
                        <p>
                          2022 Mobile Breakthrough Award Winner Small Cell
                          Technology Innovation of the Year
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </section>
          <section className="meet-our-sec">
            <Row>
              <Col md={12}>
                <div className="meetour-head">
                  <h3>
                    <span className="right-arrow">
                      <BsArrowRight />
                    </span>
                    Our leadership team
                  </h3>
                </div>
              </Col>
              <Col md={12}>
                <Tabs defaultActiveKey="home" selectedIndex={tabIndex.tabIndex}>
                  <TabList>
                    <Tab
                      eventKey="home"
                      onMouseOver={() => {
                        setTabIndex({ tabIndex: 0 })
                      }}
                    >
                      <div className="tabs-merge">
                        <div className="tab-img Allview">
                          <StaticImage
                            placeholder="tracedSVG"
                            src="../images/company/vinay-image.png"
                            alt="vinay-image"
                          />
                        </div>
                        <div className="tab-titles">
                          <h2>VINAY RAVURI</h2>
                          <h4>
                            <span className="poly">
                              <MdPlayArrow />
                            </span>
                            CEO | Founder
                          </h4>
                        </div>
                      </div>
                      <div className="cursor-scalep tabs-text">
                        <p>
                          Vinay has 25+ years of experience as a distinguished
                          industry executive and general manager for high-tech
                          corporations in compute, communications, and
                          networking. As an executive, he has navigated
                          companies to key strategic inflections in areas of
                          cloud computing, machine learning, and 5G. As Vice
                          President at Qualcomm, Vinay played an integral part
                          in the establishment of its Data Center server and
                          Machine-Learning technology, and drove their advanced
                          mobile software product management.
                        </p>
                        <p>
                          He obtained a Masters of Science from Georgia
                          Institute of Technology, a Bachelors of Engineering at
                          City University of New York, and holds 12 US patents.
                        </p>
                        <a
                          aria-label="save"
                          href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHe20R_EZzR7wAAAX8N8d1IeIZ4ab36FmwygAeaHlJUmYC2wn0UVudEDHcUC3IOhztihQHuae5aoigeahuuqM-jU4fErgLc_ej-K-wfQqsiwZCNXSXrBidYAzHBHD37VsN1wmc=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvinayravuri%2F"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedin aria-label="linkdin" />
                          <span className="company_linkdin">LinkedIn</span>
                        </a>
                      </div>
                    </Tab>
                    <Tab
                      onMouseOver={() => {
                        setTabIndex({ tabIndex: 1 })
                      }}
                    >
                      <div className="tabs-merge">
                        <div className="tab-img Allview">
                          <StaticImage
                            placeholder="tracedSVG"
                            src="../images/company/ross_img.jpg"
                            alt="ross_img"
                          />
                        </div>
                        <div className="tab-titles">
                          <h2>ROSS KATCHMAN</h2>
                          <h4>
                            <span className="poly">
                              <MdPlayArrow />
                            </span>
                            CFO
                          </h4>
                        </div>
                      </div>
                      <div className="cursor-scalep tabs-text">
                        <p>
                          Ross has 25+ years of financial, strategy and
                          corporate development experience across semiconductor,
                          software, systems and service provider markets. Prior
                          to joining EdgeQ, Ross held VP of Corporate
                          Development positions at Cadence Design Systems and
                          LSI Corporation where he led M&A and partnerships to
                          successfully strengthen Cadence’s and LSI’s market
                          reach, competitive position and revenue growth in
                          5G/wireless, enterprise and cloud infrastructure,
                          automotive and aerospace markets. Earlier in his
                          career Ross also held roles in corporate development,
                          telecom investment banking and law at Hewlett-Packard,
                          Media Venture Partners, McCutchen, Doyle, Brown &
                          Enersen and Taiwan International Patent & Law Office.
                          Ross holds a J.D. from Duke University School of Law
                          and a B.S., with distinction, from Pennsylvania State
                          University.
                        </p>
                        <a
                          aria-label="save"
                          href="https://www.linkedin.com/in/ross-katchman/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedin aria-label="linkdin" />
                          <span className="company_linkdin">LinkedIn</span>
                        </a>
                      </div>
                    </Tab>
                    <Tab
                      onMouseOver={() => {
                        setTabIndex({ tabIndex: 2 })
                      }}
                    >
                      <div className="tabs-merge">
                        <div className="tab-img Allview">
                          <StaticImage
                            placeholder="tracedSVG"
                            src="../images/company/hariprasad-image.png"
                            alt="hariprasad-image"
                          />
                        </div>
                        <div className="tab-titles">
                          <h2>HARIPRASAD GANGADHARAN</h2>
                          <h4>
                            <span className="poly">
                              <MdPlayArrow />
                            </span>
                            Head of Silicon Engineering
                          </h4>
                        </div>
                      </div>
                      <div className="cursor-scalep tabs-text">
                        <p>
                          Hari has 25 years of communications experience and
                          modem development having worked at Qualcomm, Intel,
                          Broadcom/Beceem, and Cypress. He has built and led
                          large organizations for the Design and Execution of
                          diverse SOCs including Framer Chips, Baseband Modems,
                          Base station modems and Signal Processing hardware for
                          RFIC’s. Earlier in his career, he was the Design
                          Engineering Director and the Modem HW lead at Beceem
                          which was later acquired by Broadcom. At Qualcomm, he
                          worked to define the Chipset Architecture of the
                          Snapdragon Chips. He joined EdgeQ from Intel where he
                          was Director of Engineering.
                        </p>
                        <p>
                          At EdgeQ, Hari leads all facets of design,
                          architecture, systems, IP and implementation. Hari
                          obtained a Bachelors of Technology from National
                          Institute of Technology Calicut and holds 4 US
                          patents.
                        </p>
                        <a
                          aria-label="save"
                          href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGqvMdtvLOPtgAAAX8N8h-wdkWjEmxNVST3gthLv7Ph8xBsdG9Cl0pDe6Vhkf0LtK2kwrWLcRJzdoX5wSCpDBAO7y6uuqGUUtapXgVeV-pKzDqEUuIW4V_SRpKRKp-SAVyS2wI=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fhariprasad-gangadharan-6819077%2F"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedin aria-label="linkdin" />
                          <span className="company_linkdin">LinkedIn</span>
                        </a>
                      </div>
                    </Tab>

                    <Tab
                      onMouseOver={() => {
                        setTabIndex({ tabIndex: 3 })
                      }}
                    >
                      <div className="tabs-merge">
                        <div className="tab-img Allview">
                          <StaticImage
                            placeholder="tracedSVG"
                            src="../images/company/adil-image1.png"
                            alt="adil-image"
                          />
                        </div>
                        <div className="tab-titles">
                          <h2>ADIL KIDWAI</h2>
                          <h4>
                            <span className="poly">
                              <MdPlayArrow />
                            </span>
                            Head of Product Management
                          </h4>
                        </div>
                      </div>
                      <div className="cursor-scalep tabs-text">
                        <p>
                          Adil has 20+ years of leadership experience developing
                          core wireless technologies such as 4G/5G, WiFi, WiMAX,
                          and Bluetooth. As a lead architect and technologist at
                          Intel, Adil drove pervasive adoption and market
                          success of wireless communications into high growth
                          client markets such as Phones and PCs. He was also the
                          Director of Product and IP Architecture at Intel
                          Communication Device Group where he was responsible
                          for 4G/5G Modem architecture, IPs and power
                          optimization. He then became the Director of
                          Engineering in the field of Artificial Intelligence /
                          Machine Learning, focusing on L4/L5 Autonomous Driving
                          and Datacenter Inference.
                        </p>
                        <p>
                          He obtained a Bachelor of Technology in Electrical
                          Engineering from Indian Institute of Technology,
                          followed by a Masters of Science at UCLA and a MBA
                          from UC Berkeley.
                        </p>
                        <a
                          aria-label="save"
                          href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEVhcXrWfMQ6AAAAX8N8kbAYG6N4OeGANBqr8AjZVlcuZcU_rbSDDZtfUHstjtZ-XBwEUFQpxCE2ibei2pWs_vnzLI4-F16u6-0c3FLTF5s01aVfcRGgmzKszfMzrUG1bMY0Gk=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fpublic-profile%2Fin%2Fadil-kidwai-1896366%2F%3FchallengeId%3DAQHWNjQgiMwFXAAAAXc7THdyGbimajjSSOa2p0FvK5jhtQRIO1Kd3sQ0ANETr-pcX_LtYmiRNfUQMoTD_75ifyu6wEquaIbljw%26submissionId%3D22d2bb75-9394-5d16-06d6-248726222908"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedin aria-label="linkdin" />
                          <span className="company_linkdin">LinkedIn</span>
                        </a>
                      </div>
                    </Tab>
                  </TabList>

                  {/* <TabPanel onMouseOver={() => {setTabIndex({ tabIndex: 0 })}} className="cursor-scalep tabs-text">
                        <p>
                        Vinay has 25+ years of experience as a distinguished industry executive and general manager for high-tech corporations in compute, communications, and networking. As an executive, he has navigated companies to key strategic inflections in areas of cloud computing, machine learning, and 5G. As Vice President at Qualcomm, Vinay played an integral part in the establishment of its Data Center server and Machine-Learning technology, and drove their advanced mobile software product management.
                        </p>
                        <p>
                        He obtained a Masters of Science from Georgia Institute of Technology, a Bachelors of Engineering at City University of New York, and holds 12 US patents.
                        </p>
                        </TabPanel>
                        {/* <TabPanel className="cursor-scalep tabs-text">
                        <p>
                        Hari has 25 years of communications experience and modem development having worked at Qualcomm, Intel, Broadcom/Beceem, and Cypress. He has built and led large organizations for the Design and Execution of diverse SOCs including Framer Chips, Baseband Modems, Base station modems and Signal Processing hardware for RFIC’s. Earlier in his career, he was the Design Engineering Director and the Modem HW lead at Beceem which was later acquired by Broadcom. At Qualcomm, he worked to define the Chipset Architecture of the Snapdragon Chips. He joined EdgeQ from Intel where he was Director of Engineering.
                        </p>
                        <p>
                        At EdgeQ, Hari leads all facets of design, architecture, systems, IP and implementation. Hari obtained a Bachelors of Technology from National Institute of Technology Calicut and holds 4 US patents.
                        </p>
                        </TabPanel> */}
                  {/* <TabPanel className="cursor-scalep tabs-text">
                        <p>
                        Adil has 18+ years of leadership experience developing core wireless technologies such as 4G/5G, WiFi, WiMAX, and Bluetooth. As a lead architect and technologist at Intel, Adil drove pervasive adoption and market success of wireless communications into high growth client markets such as Phones and PCs. He was also the Director of Product and IP Architecture at Intel Communication Device Group where he was responsible for 4G/5G Modem architecture, IPs and power optimization. He then became the Director of Engineering in the field of Artificial Intelligence / Machine Learning, focusing on L4/L5 Autonomous Driving and Datacenter Inference.
                        </p>
                        <p>
                        He obtained a Bachelor of Technology in Electrical Engineering from Indian Institute of Technology, followed by a Masters of Science at UCLA and a MBA from UC Berkeley.
                        </p>
                        </TabPanel> */}
                </Tabs>
              </Col>
            </Row>
          </section>
        </Container>
      </section>

      <section className="company-second-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="team-head">
                <h2>
                  Team <span className="bold">Experience</span>
                </h2>
              </div>
            </Col>
          </Row>
          <section className="counter1">
            <Row>
              <Col md={4}>
                <div className="count-box">
                  <div className="count-no">
                    <span className="counter" data-count="10">
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <div className="Count-text">
                    <h3>
                      Years of <span className="orange">4G & 5G</span> Solutions
                    </h3>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="count-box">
                  <div className="count-no">
                    <span className="counter" data-count="50">
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <div className="Count-text">
                    <h3>
                      <span className="orange">SoCs</span> Delivered to the
                      Market
                    </h3>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="count-box">
                  <div className="count-no">
                    <span className="counter" data-count="1000">
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <div className="Count-text">
                    <h3>
                      Total Years of <span className="orange">Experience</span>
                    </h3>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </section>

      <section className="company-third-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="meetour-head">
                <h3>
                  <span className="right-arrow">
                    <BsArrowRight />
                  </span>
                  Our board members
                </h3>
              </div>
            </Col>
            <Col md={12}>
              <div ref={sliderRef} className="meet-our-main-sec ">
                <Row>
                  {sliderArr.map((slides, index) => {
                    return (
                      <Col md={4}>
                        <div className="new-slider">
                          <a
                            href={slides.aLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="advisora">
                              <div className="slide-img">
                                <img
                                  src={slides.path}
                                  alt={slides.name}
                                  className="Allimg"
                                />
                              </div>
                              <div className="slide-text">
                                <h2>{slides.name}</h2>
                                <h4>
                                  {" "}
                                  <span className="poly">
                                    <MdPlayArrow />
                                  </span>{" "}
                                  {slides.designation}{" "}
                                </h4>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="company-third-sec our_adv">
        <Container>
          <Row>
            <Col md={12}>
              <div className="meetour-head">
                <h3>
                  <span className="right-arrow">
                    <BsArrowRight />
                  </span>
                  Our advisors
                </h3>
              </div>
            </Col>
            <Col md={12}>
              <div ref={sliderRef} className="meet-our-main-sec ">
                <Row>
                  {advisorSlider.map((slides, index) => {
                    return (
                      <Col md={4}>
                        <div key={index} className="new-slider">
                          <a
                            href={slides.aLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="advisora">
                              <div className="slide-img">
                                <img
                                  src={slides.path}
                                  alt={slides.name}
                                  className="Allimg"
                                />
                              </div>
                              <div className="slide-text">
                                <h2>{slides.name}</h2>
                                <h4>
                                  {" "}
                                  <span className="poly">
                                    <MdPlayArrow />
                                  </span>{" "}
                                  {slides.designation}{" "}
                                </h4>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="company-fourth-sec">
        <Container>
          <Row>
            <Col md={6}>
              <div className="be-part-left">
                <h3>
                  Be part of <span style={{ fontWeight: 275 }}>our</span> vision
                </h3>
                <h4>We are always looking for innovative thinkers.</h4>
                <Link to="/career/" className="content-btn">
                  See Open Positions
                </Link>
              </div>
            </Col>
            <Col md={6}>
              <div className="be-part-right Allimg">
                {/* <StaticImage placeholder="tracedSVG" src="../images/company/be-part-img.png" placeholder="be-part-img"/> */}
                <svg
                  width="547"
                  height="463"
                  viewBox="0 0 547 463"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M175.039 47.1418H249.094C245.93 13.8732 274.341 11.7855 274.341 11.7855H361.861C361.861 11.7855 384.178 11.7855 387.107 29.4636C387.107 45.6602 375.325 47.1418 375.325 47.1418"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M301.539 47.1418H465.201C465.201 47.1418 496.911 49.4653 479.844 79.1309"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M377.848 81.387H499.601C499.601 81.387 515.086 83.1043 516.163 98.4928C517.274 114.487 503.271 117.585 503.271 117.585H461.463"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M368.862 117.585H468.062C468.062 117.585 480.181 120.885 480.248 134.422C481.359 144.389 474.222 152.369 467.355 152.369C462.037 152.369 352.906 152.369 352.906 152.369"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M422.889 152.369C422.889 152.369 431.742 156.578 431.742 167.724C431.742 183.617 413.026 183.617 413.026 183.617H283.597C283.597 183.617 288.108 172.775 281.073 161.629C272.321 147.722 259.395 148.362 259.395 148.362H167.263C167.263 148.362 143.262 149.844 143.363 166.007C143.027 168.532 143.33 179.307 149.826 182.136"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M318.371 315.244H482.033C482.033 315.244 513.743 317.567 496.676 347.233"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M394.684 349.456H516.437C516.437 349.456 531.922 351.173 532.999 366.561C534.11 382.556 520.106 385.654 520.106 385.654H478.299"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M385.694 385.654H484.894C484.894 385.654 497.013 388.954 497.08 402.49C498.191 412.457 491.054 420.438 484.188 420.438C478.869 420.438 369.738 420.438 369.738 420.438"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M439.72 420.471C439.72 420.471 448.573 424.68 448.573 435.826C448.573 451.72 429.857 451.72 429.857 451.72H354.32"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M231.223 182.102H67.5272C67.5272 182.102 35.818 184.426 52.8844 214.091"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M154.911 216.347H33.1906C33.1906 216.347 17.7063 218.065 16.6292 233.453C15.5183 249.448 29.5215 252.545 29.5215 252.545H71.3292"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M163.899 252.545H64.6989C64.6989 252.545 52.5807 255.845 52.5134 269.382C51.4026 279.349 58.5388 287.329 65.4058 287.329C70.7243 287.329 179.855 287.329 179.855 287.329"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M109.905 287.329C109.905 287.329 101.052 291.538 101.086 302.684C101.086 318.578 119.802 318.578 119.802 318.578H266.028C266.028 318.578 261.517 307.735 268.552 296.589C277.304 282.683 290.23 283.322 290.23 283.322H382.395C382.395 283.322 403.232 283.322 404.915 299.317C405.252 301.842 406.396 310.463 398.822 314.268"
                    stroke="white"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                </svg>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
export default Company
